import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/exerciseGenerator.scss';
import { Button, Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import axios from 'axios';
import { API_URL } from '../assets/constants';
import _ from 'underscore';

/* COMPONENTS */
import PageHero from '../components/pageHero/PageHero';
import MuscleGroupButtons from '../components/muscleGroupButtons/MuscleGroupButtons';
import ExerciseDetailsModal from '../components/exerciseDetailsModal/ExerciseDetailsModal';

function ExerciseGenerator() {
  const [formData, setFormData] = useState({
    exerciseAmt: '',
    muscleGroups: '',
  });
  const [exercises, setExercises] = useState([]);
  const [selectedExercise, setSelectedExercise] = useState({});
  const [showExerciseDetailsModal, setShowExerciseDetailsModal] = useState(false);

  const handleInputChange = e => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value,
    });
  }

  const fetchExercises = e => {
      if (formData.exerciseAmt <= 0) {
        alert("Please select a positive exercise amount.");
        return;
      }
      if (!formData.muscleGroups) {
        alert("You must select a muscle group.");
        return;
      }

      // Fetch all exercises with this muscle group
      async function rMG() {
       const response = await axios.post(API_URL + `/api/exercises/byMuscleGroup`, formData);

       if (response.status !== 200) {
         const message = `An error occurred: ${response.statusText}`;
         window.alert(message);
         return;
       }

       setExercises(response.data);
      }
      rMG();    
  }

  const setMuscleGroup = group => {
    setFormData({...formData, muscleGroups: group});
  }

  const handleExerciseClick = exercise => {
    // exercise.type = _.findWhere(exercises, {_id: exercise._id}).type;
    setSelectedExercise(exercise);
    setShowExerciseDetailsModal(true);
  }

  const closeModal = () => {
    setShowExerciseDetailsModal(false);
    setSelectedExercise({});
  }  

  return (
    <div id="exercise-generator-page">
        {showExerciseDetailsModal && <ExerciseDetailsModal exercise={selectedExercise} showModal={true} closeModal={() => closeModal()} />}

        <PageHero copy="Exercise Generator" cssOptions={{
          bgImage: localStorage.getItem('bgColor'),
        }} />   

      <Container id="page-wrapper">
        <Row>
          <Col xs={12} md={6}>
            <MuscleGroupButtons muscleGroupChange={setMuscleGroup} />

            <Form.Group className="mb-3">
              <Form.Control id="exercise-amt" type="number" placeholder="No. of exercises" name="exerciseAmt" onChange={handleInputChange} value={formData.exerciseAmt} />
            </Form.Group>     

            <div className="d-grid gap-2" style={{marginBottom: '2rem'}}>
              <Button style={{cursor: 'pointer'}} size="lg" variant="primary" type="button" onClick={fetchExercises}>Submit</Button>              
            </div> 

            <section>
              <ListGroup>
                {
                  exercises.map((obj, i) => {

                    return (
                      <div key={i}>
                        <ListGroup.Item variant="primary">{obj.muscleGroupName}</ListGroup.Item>
                        {
                          obj.records.map((exercise, eI) => (
                            <ListGroup.Item key={eI} stlye={{cursor: 'pointer'}} className="d-flex justify-content-between align-items-start" as="li">
                              <div className="ms-2 me-auto">
                                <div className="fw-bold" onClick={() => handleExerciseClick(exercise)}>{exercise.name}</div>
                              </div>  
                            </ListGroup.Item>
                          ))
                        }
                      </div>
                    )
                  })
                }
              </ListGroup>
            </section>

          </Col>
        </Row>
      </Container>        
    </div>
  );
}  

export default ExerciseGenerator;
