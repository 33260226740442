import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './workoutDaysInARowBanner.scss';
import { Image } from 'react-bootstrap';
import { findWorkoutDaysInARow, findWorkoutDaysPerWeek } from '../../assets/utils';
import axios from 'axios';
import moment from 'moment';
import { API_URL } from '../../assets/constants';

/* COMPONENTS */
import BlueWorriedSmiley from '../../assets/images/icons/blue-worried-smiley.svg';
import HappyStarSmiley from '../../assets/images/icons/happy-star-smiley.svg';
import HappySweatSmiley from '../../assets/images/icons/happy-sweat-smiley.svg';
import Trophy from '../../assets/images/icons/trophy.svg';
import TrophyGray from '../../assets/images/icons/trophy-gray.svg';
import WaterBottle from '../../assets/images/icons/energy-water-bottle.svg';
import WaterBottleGray from '../../assets/images/icons/energy-water-bottle-gray.svg';

const IconSection = ({ count, target, runAnimation, animationDelay }) => {
  const [visibleIcons, setVisibleIcons] = useState(0);

  useEffect(() => {
    if (runAnimation) {
      let timer;
      if (visibleIcons < count) {
        timer = setTimeout(() => {
          setVisibleIcons((prev) => Math.min(prev + 1, count));
        }, animationDelay);
      }
      return () => clearTimeout(timer);
    } else {
      setVisibleIcons(count); // Show all icons immediately if no animation
    }
  }, [visibleIcons, count, runAnimation, animationDelay]);

  const elems = [];
  for (let i = 1; i <= target; i++) {
    if (i < target) {
      elems.push(
        <Image
          className="dir-icon"
          src={i <= visibleIcons ? WaterBottle : WaterBottleGray}
          key={i}
        />
      );
    } else {
      elems.push(
        <Image
          key={'trophy'}
          className={visibleIcons >= target ? 'dir-icon trophy-complete' : 'dir-icon trophy'}
          src={visibleIcons >= target ? Trophy : TrophyGray}
        />
      );
    }
  }

  return elems;
};

function WorkoutDaysInARowBanner({ refreshBanner, runAnimation = false, animationDelay = 500 }) {
  const [workouts, setWorkouts] = useState([]);
  const [workoutCount, setWorkoutCount] = useState(0);
  const [user, setUser] = useState(() => JSON.parse(localStorage.getItem('user')));
  const [targetDays, setTargetDays] = useState(7); // Default target
  const [isWeeklyGoal, setIsWeeklyGoal] = useState(user && user.goals ? !!user.goals.find((goal) => goal.type === 'Workout Days Per Week') : false);
  const [goalAchieved, setGoalAchieved] = useState(false);

  // Refresh the user state whenever `refreshBanner` changes
  useEffect(() => {
    const updatedUser = JSON.parse(localStorage.getItem('user'));
    setUser(updatedUser);
  }, [refreshBanner]);

  // Update targetDays and isWeeklyGoal whenever the user or goals change
  useEffect(() => {
    if (user && user.goals) {
      const workoutGoal = user.goals.find((goal) => goal.type === 'Workout Days Per Week');
      setTargetDays(workoutGoal ? workoutGoal.target : 7);
    }
  }, [user]);

  // Fetch workouts and calculate counts
  useEffect(() => {
    async function getWorkouts() {
      if (user) {
        const workoutGoal = user.goals.find((goal) => goal.type === 'Workout Days Per Week');
        const response = await axios.get(`${API_URL}/api/workouts/list/${user._id}`);
        if (response.status === 200) {
          const sortedWorkouts = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
          const count = isWeeklyGoal
            ? findWorkoutDaysPerWeek(sortedWorkouts)
            : findWorkoutDaysInARow(sortedWorkouts);
          setWorkoutCount(count);

          if (workoutGoal && (workoutGoal.target === count)) setGoalAchieved(true);
        }
      }
    }

    getWorkouts();
  }, [user, isWeeklyGoal]);

  return (
    <div id="days-in-a-row-container">
      <h1 className="text-center" style={{ marginBottom: '1rem' }}>
        {workoutCount} Workout {isWeeklyGoal ? 'day' + (workoutCount === 1 ? '' : 's') + ' this week' : 'day' + (workoutCount === 1 ? '' : 's') + ' in a row'}{' '}
        <Image
          id="smiley-face"
          className="icon"
          src={
            workoutCount >= targetDays
              ? HappyStarSmiley
              : workoutCount > 0 && workoutCount < targetDays
              ? HappySweatSmiley
              : BlueWorriedSmiley
          }
        />
      </h1>

      <div id="icon-section">
        <IconSection
          count={workoutCount}
          target={targetDays}
          runAnimation={runAnimation}
          animationDelay={animationDelay}
        />
      </div>

      {
        goalAchieved &&
        <div id="goal-achieved-callout">
          <div style={{textAlign: 'center', color: 'gold', fontFamily: 'Raleway, sans-serif'}}>WEEKLY GOAL ACHIEVED!</div>
        </div>
      }
    </div>
  );
}

export default WorkoutDaysInARowBanner;
